import React from "react"
import {Link} from "gatsby";

//input object with categories (name, slug, wpid), output unorderlist met tags

const TagList = (props) =>


{
    let tags = props.tags


    return (
        <ul className="StyledMetaTax">

            {tags.map(tag => (
                <li key={tag.name}><Link className="StyledLink" to={`/tag/${tag.slug}/`}>{tag.name}</Link></li>

            ))}
        </ul>
    )
}


export default TagList;