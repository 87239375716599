import React, { Component } from "react"
import { Link, graphql } from "gatsby"
import PropTypes from "prop-types"

import Layout from "../components/layout"
import Img from "gatsby-image";
import Tags from "../components/tagList"
import SEO from "../components/seo";



class Projects extends Component {
    render() {
        const data = this.props.data

        return (
            <Layout>

                <SEO
                    title="Projects"
                    description="Sample projects learning new skills"
                />




                {data.allWordpressWpProjects.edges.map(({ node }) => (



                    <div key={node.id}>


                        <h2  className="click-header">
                        <Link to={`/projects/${node.slug}`}>
                           {node.title}
                        </Link></h2>
                        <Img
                            alt={node.featured_media.alt_text}
                            fluid={node.featured_media.localFile.childImageSharp.fluid}

                        />


                        <Tags tags={node.tags} />



                        <div

                            dangerouslySetInnerHTML={{ __html: node.excerpt }}
                        />
                        <Link
                            to={`/projects/${node.slug}`}
                            aria-label={`Continue reading ${node.title}`}
                        >
                            <button  className="StyledLinkButton">
                                Continue reading
                            </button>
                        </Link>
                    </div>
                ))}

            </Layout>
        )
    }
}

Projects.propTypes = {
    data: PropTypes.object.isRequired,
    edges: PropTypes.array,
}

export default Projects

export const pageQuery = graphql`
  query {
    allWordpressWpProjects(sort: { fields: [date], order: DESC }) {
      edges {
        node {
        id
          title
          excerpt
          slug
          tags {
        id
        name
        slug
      }
                featured_media {
        source_url
        alt_text
        localFile {
          childImageSharp {
            fluid(maxWidth: 804) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
